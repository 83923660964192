.csl-footer-simple-background {
  background-color: #ffff !important;
}

.csl-footer-simple-background .csl-footer-simple {
  padding: 50px 15px 30px 15px !important;
}

.footer-content {
  justify-content: left;
}

.footer-content p {
  font-size: 18px !important;
}

.footer-img > * {
  margin: 4px 0;
}

.footer-content .nav-link {
  color: black !important;
}

.footer-content .limiter {
  padding: 0 15px;
}

.footer-logo {
  width: 150px;
}

.announce_btn {
  /* padding: 20px 15px !important; */
  height: 40px;
  border: none;
  transition: 0.15s ease-in-out;
  text-decoration: none;
  color: white;
  /* cursor: pointer; */
}
/* 
.announce_btn:hover {
  background-color: #fc1921 !important;
} */

.right_arrow_icon {
  width: 10px;
}

@media (min-width: 992px) {
  .announce_btn {
    height: 45px;
    /* padding: 10px 0 !important; */
  }
}

@media (min-width: 1200px) {
  .csl-footer-simple-background .csl-footer-simple {
    padding: 50px 50px 50px 125px !important;
  }
}
