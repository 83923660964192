@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

* {
  font-family: "Montserrat";
}

body,
html {
  margin: 0px;
  padding: 0px;
}

.csl-color {
  color: #fc1921;
}
.Background-Red {
  background-color: #fc1921;
  border: none;
}

.billdboard-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Main-Image {
  height: 80vh;
}
.Billboards-Image {
  height: 90vh;
  object-fit: cover;
}

.Phone-Social-Media-Image {
  width: 30%;
  pointer-events: none;
  z-index: 3;
}

.M-Header {
  font-weight: 500;
}

.amazing-text {
  position: relative;
  font-weight: 800;
  letter-spacing: 5px;
}

.amazing-icon {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
}

.fontsize16700 {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.csl-960-content {
  width: 100%;
}

.csl-1000-content {
  width: 100%;
}

.csl-1200-content {
  width: 100%;
}

.fontsize32-24 {
  font-size: 24px;
  font-weight: 700;
}

.fontsize16 {
  font-size: 16px;
  margin-bottom: 0;
}

.csl-full-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 15px;
}

.custom-progress {
  height: 10px !important;
}

@media screen and (min-width: 800px) {
  .Explore-Video {
    height: 600px;
  }

  .SocialMedia-Container {
    position: relative;
    height: 90vh;
  }

  .Explore-Margin {
    margin-left: 15%;
    margin-right: 15%;
  }

  .M-Header {
    font-size: 75px;
  }

  .Header {
    width: 100%;
    height: 80px;
    z-index: 5;
  }
  .Explore-Header {
    width: auto;
    max-width: 900px;
    font-size: 42px;
    font-weight: 650;
  }

  .All-Header {
    width: auto;
    font-size: 42px;
    font-weight: 650;
  }
  .Image-box {
    margin-left: 200px;
    height: 33px;
  }

  .Explore-Content {
    font-size: 18px;
    font-weight: 400;
  }
  .Billboards-Image {
    position: relative;
    z-index: 1;
  }
  .Billdboards-Poster-Box {
    box-sizing: border-box;
    margin: -140px auto auto auto;
    width: 72%;
    position: relative;
    z-index: 2;
    padding: 30px;
  }

  .All-Margin {
    margin-left: 14%;
    margin-right: 14%;
  }

  .Margin-Top {
    margin-top: 140px;
  }

  .M-Icon {
    width: 30px;
    height: 30px;
  }
}

.csl-gap-3 {
  gap: 30px;
}

@media screen and (max-width: 799px) {
  .All-Margin {
    margin-left: 4%;
    margin-right: 4%;
  }
  .M-Header {
    font-size: 40px;
  }
  .Billboards-Image {
    height: 400px;
  }

  .Header {
    width: 100%;
    height: 40px;
    z-index: 5;
  }
  .Image-box {
    margin-left: 30px;
    height: 20px;
  }

  .Explore-Video {
    height: 250px;
  }

  .Explore-Header,
  .All-Header {
    width: 95%;
    font-size: 24px;
    font-weight: 650;
  }
  .Explore-Content {
    font-size: 16px;
    font-weight: 400;
  }

  .Explore-Margin {
    margin-left: 2%;
    margin-right: 2%;
  }

  .Billdboards-Poster-Box {
    box-sizing: border-box;
    margin: -70px auto auto auto;
    width: 80%;
    position: relative;
    padding: 15px;
  }
  .Margin-Top {
    margin-top: 40px;
  }
}

.SocialMedia-Container {
  position: relative;
  height: 90vh;
}

/* @media screen and (max-width: 1899px) {
  .SocialMedia-Container {
    position: relative;
    height: 60vh;
  }
}
@media screen and (max-width: 1699px) {
  .SocialMedia-Container {
    position: relative;
    height: 600px;
  }
} */

@media screen and (max-width: 1025px) {
  .M-Icon {
    width: 23px;
    height: 23px;
  }

  .SocialMedia-Container {
    position: relative;
    height: 350px;
  }

  .Main-Image {
    height: 500px;
  }

  .Billboards-Image {
    height: 500px;
  }

  #AudioProgressBarSoundContainer,
  #AudioProgressBarContainer {
    margin-top: 3px;
  }
}

@media screen and (max-width: 650px) {
  .M-Icon {
    width: 20px;
    height: 20px;
  }

  .SocialMedia-Container {
    position: relative;
    height: 300px;
  }

  .Main-Image {
    height: 250px;
  }

  .Billboards-Image {
    height: 250px;
  }

  #AudioProgressBarSoundContainer,
  #AudioProgressBarContainer {
    margin-top: 6px;
  }
}

@media screen and (max-width: 450px) {
  .Billboards-Image {
    height: 230px;
  }
  .SocialMedia-Container {
    position: relative;
    height: 140px;
  }
}
#AudioContainer > div,
div > .Row-No-Gap {
  --bs-gutter-x: 0rem;
}
.Shadow-Box {
  -webkit-box-shadow: 3px 3px 26px 5px lightgrey;
  -moz-box-shadow: 3px 3px 26px 5px lightgrey;
  box-shadow: 3px 3px 26px 5px lightgrey;
}

.Shadow-Box-2 {
  box-shadow: 1px 1px 3px lightgrey;
}
.Footer-Padding {
  padding: 50px;
}

.Footer-Link {
  text-decoration: none;
  color: black;
  font-weight: bolder;
}

.Border-Right {
  border-right: 1px solid black;
}

.Separate-Box {
  width: 100%;
  height: 60px;
  background-color: black;
}

.Separate-Box:hover {
  background-color: red;
}

.DirectMail-Margin {
  margin-left: 7%;
}

.SocialMedia-Image-Width {
  width: 32%;
}

#audioControlPause,
#audioSoundMuted {
  display: none;
}

#audioControlPlay,
#audioControlPause,
#audioSoundMute,
#audioSoundMuted,
#time {
  color: white;
}

#AudioProgressBar,
#AudioProgressBarSound {
  background-color: white;
}

.Total {
  color: #cfcfcf;
}

#AudioContainer {
  background-color: #4e4e4e;
}

.Audio_Tool {
  max-width: 400px;
}

#Radio-Tracker {
  position: absolute;
  z-index: 9;
}

#Radio-Tracker-Icon {
  cursor: move;
  margin-top: -8px;
  z-index: 10;
  background-color: white;
}
.Radio-Dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
}

#Audio-Range-Timer,
#Audio-Range-Sound {
  background-color: transparent;
  z-index: 1;
}

#AudioProgressBarSound {
  width: 100%;
}

#Audio-Range-Timer[type="range"]::-webkit-slider-runnable-track,
#Audio-Range-Sound[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
}

#Audio-Range-Timer[type="range"]::-moz-range-track,
#Audio-Range-Sound[type="range"]::-moz-range-track {
  background: transparent;
}

#Audio-Range-Timer[type="range"]::-moz-range-thumb,
#Audio-Range-Sound[type="range"]::-moz-range-thumb {
  background: #ffffff;
  -moz-appearance: none;
  margin-top: -8px;
  padding: 6px;
  box-shadow: -1px -1px 10px black;
}

#Audio-Range-Timer[type="range"]::-webkit-slider-thumb,
#Audio-Range-Sound[type="range"]::-webkit-slider-thumb {
  background: #ffffff;
  -webkit-appearance: none;
  margin-top: -8px;
  padding: 12px;
  box-shadow: 1px 1px 10px black;
}

.M-Image-Box {
  clip-path: polygon(0 0, 100% 0%, 100% 94%, 0% 100%);
  z-index: 1;
  filter: brightness(75%);
}

.Text-Image {
  z-index: 5;
  text-align: center;
}

.Black-Screen-Container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.csl-button {
  background-color: red;
  color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 3rem;
  box-shadow: 0 3px 6px #00000029 !important;
}

.csl-button:hover {
  background-color: white;
  color: red;
}
/* 
.Animtion-Text-FadeIn .All-Header,
.Explore-Header {
  opacity: 0;
  transition: opacity 1s ease-in;
} */

/* .Animtion-Text-FadeIn.Animated .All-Header {
  opacity: 1;
  animation-name: slide;
  animation-duration: 2s;
  animation-timing-function: linear;
} */

/* .Animtion-Text-FadeIn.Animated .Explore-Header {
  opacity: 1;
  animation-name: slide;
  animation-duration: 2s;
  animation-timing-function: linear;
} */
/* 
.All-Header:hover {
  opacity: 1;
} */

.iframe-section {
  position: relative;
}

.iframe-section .action-iframe-area {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.modal-header {
  border: none !important;
  padding: 0 !important;
}

.modal-embed-video {
  height: 200px;
  padding: 3px !important;
}

.modal-embed-video .ratio-21x9 {
  height: 100%;
}

.border_btn_white_res {
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  height: 60px !important;
  border-radius: 30px !important;
  border: 2px solid white !important;
  color: white !important;
  font-weight: 650 !important;
  padding: 0 50px;
}

.progress, 
.progress-stacked{
  overflow: visible !important;
}
/* 
@keyframes bounce {
  20%,
  50%,
  80%,
  to {
    transform: scale(1, 1);
  }
  40%,
  50% {
    transform: scale(1.75, 0.65);
  }
  60% {
    transform: scale(1.25, 0.75);
  }
  90% {
    transform: scale(1.15, 0.85);
  }
} */


@media (min-width: 768px) {
  .fontsize32-24 {
    font-size: 32px;
  }
  .csl-gap-3 {
    gap: 3rem;
  }
}

@media (min-width: 992px) {
  .csl-960-content {
    width: 960px;
  }
  .modal-embed-video {
    height: 400px;
  }
}

@media (min-width: 1200px) {
  .csl-1000-content {
    width: 1000px;
  }
  .csl-1200-content {
    width: 1200px;
  }
  .csl-full-section {
    padding: 80px 0px;
  }
  .csl-confined-content {
    max-width: 1440px !important;
    margin: auto;
  }
}

.Explore-Image{
  width: 100%;
  height: 100%;
  object-fit: contain;
}